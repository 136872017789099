<template>
  <div id="userManage">
    <header-nav :content="`${$t('pages_router_023')} / ${ $t('pages_router_032')}`" />
    <div v-if="getUsedCheck(pageLabs, 170201)" class="common-card-body">
      <div class="insert-btn">
        <a-button
          shape="round"
          type="primary"
          ghost
          @click="userEdit(false)"
        >
          <template #icon><plus-circle-filled /></template>
          {{ $t('pages_user_021') }}
        </a-button>
      </div>
      <div class="table-part">
        <a-table
          :scroll="{ x: 1200 }"
          :loading="loading"
          :pagination="false"
          :custom-header-row="() => { return {class: 'my-table-tr'}}"
          :columns="columns"
          :data-source="users"
        >
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'edit'">
              <a-space style="width: 100%; justify-content: space-between">
                <a-button
                  shape="round"
                  size="small"
                  type="primary"
                  @click="userEdit(record)"
                >{{ $t('pages_user_027') }}</a-button>
                <a-popconfirm :title="$t('pages_user_046')" @confirm="() => passwordReset(record.id)">
                  <a-button
                    shape="round"
                    size="small"
                    type="primary"
                    danger
                  >{{ $t('pages_user_028') }}</a-button>
                </a-popconfirm>
                <!-- <a-popconfirm :title="$t('pages_user_030')" @confirm="() => userDelete(record.id)">
                  <a-button
                    shape="round"
                    size="small"
                    type="primary"
                    danger
                  >{{ $t('pages_user_029') }}</a-button>
                </a-popconfirm> -->
              </a-space>
            </template>
          </template>
        </a-table>
      </div>
    </div>
    <a-alert v-else style="text-align: left; margin-top: 24px" message="No permissions" banner />
    <user-insert
      ref="editRef"
      v-model:visible="visible"
      :user-info="userInfo"
      @refresh="loadUsers"
    />
  </div>
</template>
<script>
import HeaderNav from '@/components/header-nav'
import UserInsert from './components/user-insert'
import { ref, reactive, toRefs } from 'vue'
import { _user, _account } from '@/api'
import { getTradeStatusName, getUsedCheck } from '@/util'
import { message } from 'ant-design-vue'
import i18n from '@/locale'
export default {
  name: 'UserManage',
  components: {
    'header-nav': HeaderNav,
    'user-insert': UserInsert
  },
  setup () {
    const editRef = ref(null)
    const state = reactive({
      loading: false,
      visible: false,
      users: [],
      pageLabs: [],
      columns: [
        { key: 'name', dataIndex: 'name', title: i18n.global.t('pages_user_022'), width: 200, ellipsis: true },
        { key: 'roles', dataIndex: 'roles', title: i18n.global.t('pages_user_023'), width: 200 },
        { key: 'mobile', dataIndex: 'mobile', title: i18n.global.t('pages_user_005'), width: 150 },
        { key: 'email', dataIndex: 'email', title: i18n.global.t('pages_user_025'), width: 200, ellipsis: true },
        { key: 'status', dataIndex: 'statusText', title: i18n.global.t('pages_user_026'), width: 120 },
        { key: 'edit', title: i18n.global.t('common_text_001'), align: 'center', fixed: 'right', width: localStorage.getItem('locale-language') === 'en' ? 200 : 160 }
      ]
    })

    const userEdit = (record) => {
      editRef.value.initParams(record)
      state.visible = true
    }

    const passwordReset = (userId) => {
      _user.resetPassword({ userId })
        .then(res => {
          if (res.data.succ) message.success(i18n.global.t('pages_user_047'))
          else message.error(res.data.resMsg)
        }).catch(err => console.log(err))
    }

    const userDelete = (deleteId) => {
      console.log(deleteId)
    }

    const loadUsers = async () => {
      state.loading = true
      const res = await _user.getUserList()
      if (res.data.succ) {
        const language = localStorage.getItem('locale-language')
        state.users = res.data.data.map(item => {
          return Object.assign({}, item, {
            statusText: getTradeStatusName(item.status, 'userStatus'),
            roles: language === 'en' ? item.enRoles : item.roles
          })
        })
      }
      state.loading = false
    }

    const loadChildrenMenu = async () => {
      const res = await _account.getChildrenMenu({ parentId: 1702 })
      if (res.data.succ) {
        state.pageLabs = res.data.data || []
      }
    }

    const init = () => {
      loadChildrenMenu()
      loadUsers()
    }

    init()

    return {
      editRef,
      userEdit,
      userDelete,
      loadUsers,
      getUsedCheck,
      passwordReset,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
#userManage {
  .common-card-body {
    .insert-btn {
      display: flex;
      justify-content: flex-end;
      padding: 0 18px;
    }
    .table-part {
      margin-top: 24px;
    }
  }
}
</style>

