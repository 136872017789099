<template>
  <a-modal
    :visible="visible"
    :mask-closable="false"
    :width="548"
    :title="userForm.id ? $t('pages_user_031'): $t('pages_user_021')"
    :confirm-loading="loading"
    :ok-text="$t('common_text_002')"
    @ok="handleSubmit"
    @cancel="$emit('update:visible', false)"
  >
    <a-form ref="userRef" :model="userForm" :rules="userRules" layout="vertical">
      <a-form-item name="name" :label="$t('pages_user_032')">
        <a-input
          v-model:value="userForm.name"
          allow-clear
          :placeholder="$t('common_text_006') + $t('pages_user_032')"
        />
      </a-form-item>
      <a-form-item name="dept" :label="$t('pages_user_033')">
        <a-input
          v-model:value="userForm.dept"
          allow-clear
          :placeholder="$t('common_text_006') + $t('pages_user_033')"
        />
      </a-form-item>
      <a-form-item
        v-if="!userForm.id"
        name="mobile"
        :label="$t('pages_user_005')"
      >
        <div class="flex-align">
          <a-form-item-rest>
            <a-select v-model:value="areaPhoneCode" show-search style="flex: 1">
              <template #suffixIcon>
                <img src="~@/assets/icons/ic-choose.png" style="width: 11px; margin-top: -3px">
              </template>
              <a-select-option
                v-for="(item, index) in areaCodes"
                :key="index"
                :value="item"
                :label="item"
              />
            </a-select>
          </a-form-item-rest>
          <a-input
            v-model:value="userForm.mobile"
            allow-clear
            style="margin-left: 24px; flex: 3"
            :maxlength="11"
            :placeholder="$t('common_text_006') + $t('pages_user_005')"
          />
        </div>
      </a-form-item>
      <a-form-item v-else name="mobile" :label="$t('pages_user_005')">
        <a-input v-model:value="userForm.mobile" disabled />
      </a-form-item>
      <a-form-item
        v-if="!userForm.id"
        name="verificationCode"
        :label="$t('pages_user_020')"
      >
        <div class="flex-align">
          <a-input
            v-model:value="userForm.verificationCode"
            allow-clear
            :placeholder="$t('common_text_006') + $t('pages_user_020')"
          />
          <a-button
            type="primary"
            style="margin-left: 24px"
            :disabled="countNum > 0"
            :loading="codeLoading"
            @click="sendCode"
          >{{ countNum > 0 ? `${countNum}${$t('pages_user_018')}` : $t('pages_user_017') }}</a-button>
        </div>
      </a-form-item>
      <a-form-item
        name="email"
        :label="$t('pages_user_025')"
      >
        <a-input
          v-model:value="userForm.email"
          allow-clear
          :disabled="userForm.id ? true : false"
          :placeholder="$t('common_text_006') + $t('pages_user_025')"
        />
      </a-form-item>
      <a-form-item
        name="roles"
        :label="$t('pages_user_034')"
      >
        <a-checkbox-group v-model:value="userForm.roles">
          <a-checkbox
            v-for="role in roleList"
            :key="role.value"
            :value="role.value"
          >
            <a-tooltip>
              <template #title>
                <div v-html="role.explain" />
              </template>
              {{ role.label }}
            </a-tooltip>
          </a-checkbox>
        </a-checkbox-group>
      </a-form-item>
      <a-form-item
        name="status"
        :label="$t('pages_user_026')"
      >
        <a-switch
          v-model:checked="userForm.status"
          :checked-children="$t('pages_user_042')"
          :un-checked-children="$t('pages_user_043')"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import { ref, reactive, toRefs, onUnmounted } from 'vue'
import { _user } from '@/api'
import { message } from 'ant-design-vue'
import i18n from '@/locale'
export default {
  name: 'UserInsert',
  props: {
    visible: {
      type: Boolean,
      default: () => { return false }
    }
  },
  setup(props, ctx) {
    let timer = ref(null)
    const userRef = ref(null)

    const state = reactive({
      countNum: 0,
      loading: false,
      codeLoading: false,
      areaPhoneCode: '',
      areaCodes: [],
      roleList: [],
      userForm: {
        id: '',
        name: '',
        dept: '',
        mobile: '',
        email: '',
        status: true,
        roles: [],
        verificationCode: ''
      },
      userRules: {
        name: [{ required: true }],
        mobile: [{ required: true }],
        email: [{ required: true }],
        roles: [{ required: true }],
        verificationCode: [{ required: true, message: i18n.global.t('pages_user_020') + i18n.global.t('common_text_042') }],
        status: [{ required: true }]
      }
    })

    onUnmounted(() => {
      clearInterval(timer)
    })

    const initParams = (record) => {
      if (record) {
        const roleNames = record.roles.split(',')
        const roles = []
        for (const name of roleNames) {
          const li = state.roleList.find(item => item.label === name)
          if (li) roles.push(li.value)
        }
        state.userForm.id = record.id
        state.userForm.name = record.name
        state.userForm.dept = record.dept
        state.userForm.mobile = record.mobile
        state.userForm.email = record.email
        state.userForm.status = record.status === '01'
        state.userForm.roles = roles
      } else {
        state.userForm = {
          id: '',
          name: '',
          dept: '',
          mobile: '',
          email: '',
          status: true,
          roles: [],
          verificationCode: ''
        }
      }
    }

    const sendCode = async () => {
      state.codeLoading = true
      const res = await _user.getMspVerificationCode({
        mobile: state.userForm.mobile,
        areaPhoneCode: state.areaPhoneCode
      })
      state.codeLoading = false
      if (res.data.succ) {
        state.countNum = 60
        timer = setInterval(() => {
          state.countNum--
          if (state.countNum === 0) clearInterval(timer)
        }, 1000)
      } else {
        message.error(res.data.resMsg)
      }
    }

    const handleSubmit = () => {
      userRef.value.validateFields()
        .then(values => {
          state.loading = true
          const data = Object.assign({}, state.userForm, { status: state.userForm.status ? '01' : '02' })
          return _user.addOrUpdateUserManage(data)
        })
        .then(res => {
          state.loading = false
          if (res.data.succ) {
            message.success(i18n.global.t(state.userForm.id ? 'pages_user_045' : 'pages_user_044'))
            ctx.emit('update:visible', false)
            ctx.emit('refresh')
            userRef.value.resetFields()
          } else {
            message.error(res.data.resMsg)
          }
        }).catch(err => console.log(err))
    }

    const loadPhoneAreaCode = async () => {
      const res = await _user.getPhoneAreaCode()
      if (res.data.succ) {
        state.areaCodes = res.data.data
        for (const item of state.areaCodes) {
          if (item === '86') state.areaPhoneCode = '86'
        }
        if (!state.areaPhoneCode) state.areaPhoneCode = state.areaCodes[0]
      }
    }

    const loadUserRoleName = async () => {
      const res = await _user.getUserRoleName({ userId: '' })
      if (res.data.succ) {
        const roleList = []
        for (const i in res.data.data) {
          let label = ''
          let explain = ''
          if (i === '500') {
            label = i18n.global.t('pages_user_035')
            explain = i18n.global.t('pages_user_024')
          } else if (i === '501') {
            label = i18n.global.t('pages_user_036')
            explain = i18n.global.t('pages_user_039')
          } else if (i === '502') {
            label = i18n.global.t('pages_user_037')
            explain = i18n.global.t('pages_user_040')
          } else {
            label = i18n.global.t('pages_user_038')
            explain = i18n.global.t('pages_user_041')
          }
          roleList.push({ label, value: i, explain })
        }
        state.roleList = roleList
      }
    }

    loadPhoneAreaCode()
    loadUserRoleName()

    return {
      userRef,
      sendCode,
      initParams,
      handleSubmit,
      ...toRefs(state)
    }
  }
}
</script>

